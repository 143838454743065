<template>
  <div class="evenement" v-if="afficher">
    <article class="message">
      <div class="message-header">
        <p>{{ evenement.title }}</p>
        <button class="delete ml-2" aria-label="delete" @click="fermer"></button>
      </div>
      <div class="message-body">
        <template v-for="absence in absencesValides">
          <absence :key="absence.id" :absence="absence" :bloquer="absencesValides.length != evenement.absences.length"/>
        </template>
        <p class="buttons mt-4">
          <button @click="effacer" class="button is-small">
            <span class="icon is-small">
              <i class="fa fa-trash"></i>
            </span>
            <span>Retirer {{ absencesValides.length }} jour(s)</span>
          </button>
        </p>
      </div>
    </article>
  </div>
</template>

<script>
import Absence from "@/components/Absence.vue";
export default {
  components: { Absence },
  data() {
    return {
      afficher: false,
      evenement: false,
    };
  },
  mounted() {
    this.$bus.$on("afficher-evenement", (evenement) => {
      if(evenement.absences[0]?.type == "jour-ferie") return;
      if(evenement.absences[0]?.type == "vacance") return;
      this.afficher = true;
      this.evenement = evenement;
    });
  },
  computed: {
    absencesValides() {
      let out = [];
      for (const absence of this.evenement.absences) {
        if (!this.isSaturday(absence.date)) {
          out.push(absence)
        }
      }
      return out;
    }
  },
  methods: {
    fermer() {
      this.$bus.$emit("charger-absences");
      this.afficher = false;
    },
    effacer() {
      // if (
      //   confirm(
      //     `Voulez-vous effacer cette absence allant du ${this.evenement.start} au ${this.evenement.end} ? `
      //   )
      // ) {
      let where = {
        id: this.evenement.absences.map((el) => el.id),
      };

      this.api
        .delete("table", { params: { nom: "absences", where } })
        .then((response) => {
          this.fermer();
        });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.evenement {
  position: fixed;
  z-index: 100;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);

  >* {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>