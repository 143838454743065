<template>
  <div>
    <div>
      <input type="date" v-model="debut" />
      <input type="date" v-model="fin" />
    </div>

    <div.<div class="columns">
      <div class="column is-half">
        <canvas ref="absences" width="400" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      absences: [],
      debut: "",
      fin: "",
      loading: false,
    };
  },
  watch: {
    debut() {
      this.chargerGraphs();
    },
    fin() {
      this.chargerGraphs();
    },
  },
  mounted() {
    this.debut = this.debutMois();
    this.fin = this.finMois();
    this.chargerGraphs();
  },
  computed: {
    intervaleJours() {
      let debut = new Date(this.debut);
      let fin = new Date(this.fin);
      let jours = [];
      while (debut < fin) {
        jours.push(debut.toLocaleString("fr-FR", {month:'numeric',day:'numeric'}));
        debut.setDate(debut.getDate() + 1);
      }
      return jours;
    },
  },
  methods: {
    chargerGraphs() {
      this.loading = true;
      this.graphAbsences();
    },
    graphAbsences() {
      let where = {
          type : 'conge',
        "date:>=": this.debut,
        "date:<=": this.fin
      };
      this.api
        .get("table", { params: { nom: "absences", where } })
        .then((response) => {
          let absences = response.data;
          let absencesJour = []
          absences.forEach(absence => {
            this.intervaleJours.forEach(jour => {
                console.log(jour, absence)
                // absencesJour.push()
            })
          });
          
          //   let chartAbsences = new Chart(this.$refs["absences"]);
          let chart = new Chart(this.$refs["absences"], {
            type: "bar",
            data: {
              labels: this.intervaleJours,
              datasets: [
                {
                  label: "Congés",
                  data: [12, 19, 3, 5, 2, 3],
                },
              ],
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style>
</style>