import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: 'absences',
    reducer(state, path) {
      let reducer = Object.assign({}, state)
      delete reducer.planning;
      return reducer;
    }
  })],
  state: {
    typesAbsences: false,
    session: false,
    absences: [],
    user: false,
    equipe: false,
    planning: {

    }
  },
  getters: {
    isAdmin(state, getters) {
      if (getters.isConnected) {
        if (state.user.admin) {
          return true;
        }
      }
    },
    isConnected(state) {
      if (state.session && state.absences && state.user) {
        return true;
      } else {
        return false;
      }
    },
    typesAbsences(state) {
      return state.typesAbsences;
    },
    personneId(state) {
      return state.user ? state.user['personne_id'] : false;
    },
    userSlug(state) {
      return state.user ? state.user.slug : false;
    },
    user(state) {
      return state.user ? state.user : false;
    }
  },
  mutations: {
    setTypesAbsences(state, typesAbsences) {
      state.typesAbsences = typesAbsences;
    },
    setEquipe(state, equipe) {
      state.equipe = equipe;
    },
    ajouterAbsence(state, absence) {
      state.absences.push(absence);
    },
    setAbsences(state, absences) {
      state.absences = absences;
    },
    resetUser(state) {
      state.session = false;
      state.user = false;
    },
    setSession(state, data) {
      state.session = data.session_id;
      state.user = data.user;
    }
  },
  actions: {
    
  },
  modules: {
  }
})
