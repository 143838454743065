<template>
  <div class="column">
    <h1 class="title is-5">Absences</h1>
    <h2 class="subtitle is-6">Congés ou autre</h2>
    <aside class="menu">
      <p class="menu-label">General</p>
      <ul class="menu-list">
        <li>
          <router-link
            :to="{name:'PlanningUser',params:{slug:$store.getters.userSlug, personne_id:$store.getters.personneId}}"
          >Mon planning</router-link>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.dropbox.com/s/ke8wypi3gaan8dr/Tuto%20Outils%20cong%C3%A9s%20%26%20absences.pdf?dl=0"
          >Aide</a>
        </li>
        <!-- <li>
          <router-link to="/stats">Stats</router-link>
        </li>-->
      </ul>
      <template v-if="$store.state.user.teams">
        <p class="menu-label">Equipes</p>
        <ul class="menu-list">
          <li v-for="team in $store.state.user.teams" :key="team.id">
            <router-link :to="{name:'PlanningEquipe',params:{equipe_id:team.id}}">{{team.name}}</router-link>
            <template v-if="$store.state.equipe && $store.state.equipe.id == team.id">
              <ul>
                <li v-for="user in $store.state.equipe.users" :key="user.id">
                  <router-link
                    :to="{name:'PlanningEquipeUser',params:{equipe_id:team.id, slug:user.slug, personne_id:user.personne_id}}"
                  >{{user.nom}}</router-link>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </template>
      <p class="menu-label">Compte</p>
      <ul class="menu-list">
        <li>
          <a
            target="_blank"
            href="https://sogest.sopress.net/index.php?w=edit_personne&mode=perso"
          >Profil</a>
        </li>
        <li>
          <a @click="logOut">Se déconnecter</a>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.column {
  flex: initial;
  max-width: 200px;
  width: 100%;
}
</style>