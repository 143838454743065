<template>
  <div class="media" v-if="afficher">
    <div class="media-content">
      <nav class="level is-mobile">
        <div class="level-left">
          {{ absence.date }}
          <span class="tag">{{ valeur == 1 ? 'Journée entière' : 'Demie-journée' }}</span>
        </div>
        <div class="level-right">
          <template v-if="!absenceIsSaturday && !bloquer">
            <a @click="effacerAbsence" class="level-item" aria-label="Retirer ce jour" data-balloon-pos="up">
              <span class="icon is-small">
                <i class="fas fa-trash"></i>
              </span>
            </a>
            <template v-if="valeur == 1">
              <a @click="demiJournee" class="level-item" aria-label="Transformer ce jour entier en demi-journée"
                data-balloon-pos="up">
                <span class="icon is-small">
                  <i class="fas fa-star-half-alt"></i>
                </span>
              </a>
            </template>
            <template v-else>
              <a @click="demiJournee" class="level-item" aria-label="Transformer cette demie-journée jour en jour entier"
                data-balloon-pos="up">
                <span class="icon is-small">
                  <i class="fas fa-star"></i>
                </span>
              </a>
            </template>
          </template>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["absence", "bloquer"],
  data() {
    return {
      afficher: true,
      valeur: false
    };
  },
  mounted() {
    this.valeur = this.absence.valeur;
  },
  computed: {
    absenceIsSaturday() {
      return this.isSaturday(this.absence.date);
    }
  },
  methods: {
    demiJournee() {
      this.api
        .post("demi-journee", {
          id: this.absence.id,
        })
        .then((response) => {
          this.valeur = response.data.valeur;
        });
    },
    effacerAbsence() {
      // if (confirm("Effacer l'absence du " + this.absence.date + " ? ")) {
      let where = {
        id: this.absence.id,
      };

      this.api
        .delete("table", { params: { nom: "absences", where } })
        .then((response) => {
          //   this.$bus.$emit("charger-absences");
          this.afficher = false;
        });
      // }
    },
  },
};
</script>

<style></style>