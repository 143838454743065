<template>
  <nav class="pagination is-right" role="navigation" aria-label="pagination" v-if="user">
    <ul class="pagination-list">
      <li>
        <router-link
          :to="{ name: 'PlanningAnneeMois', params: moisPrecedent }"
          class="pagination-previous"
        >&laquo;</router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'PlanningAnneeMois', params: { annee: annee, mois: mois + 1, slug : user.slug, personne_id : user.personne_id }}"
          class="pagination-link is-current button"
          :class="{ 'is-loading': loading }"
        >{{ nomMois(annee, mois) }} {{ annee }}</router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'PlanningAnneeMois', params: moisSuivant }"
          class="pagination-next"
        >&raquo;</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["annee", "mois", "user", "loading"],
  computed: {
    moisSuivant() {
      let ret = {
        slug : this.user.slug, 
        personne_id : this.user.personne_id,
        mois: this.mois,
        annee: this.annee,
      };

      if (this.mois == 11) {
        ret.annee++;
        ret.mois = 0;
      } else {
        ret.mois++;
      }
      ret.mois++;
      return ret;
    },
    moisPrecedent() {
      let ret = {
        slug : this.user.slug, 
        personne_id : this.user.personne_id,
        mois: this.mois,
        annee: this.annee,
      };

      if (this.mois == 0) {
        ret.annee--;
        ret.mois = 11;
      } else {
        ret.mois--;
      }
      ret.mois++;
      return ret;
    },
  },
};
</script>

<style>
</style>