import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'balloon-css';
import 'bulma';
import Chart from 'chart.js';
//import { Sso } from './mixins/sso'Vue.mixin(Sso);


import { Outils } from './mixins/outils'
Vue.mixin(Outils);

import { Absences } from './mixins/absences'
Vue.mixin(Absences);


import fullCalendar from 'vue-fullcalendar'
Vue.component('full-calendar', fullCalendar)

Vue.prototype.$bus = new Vue();

Vue.prototype.api = axios.create({
	baseURL: process.env.VUE_APP_API,
	headers: {
		'Authorization': process.env.VUE_APP_SOGEST_SECRET,
	}

});

Vue.prototype.api.interceptors.request.use((config) => {
	let form_data = new FormData();

	for (var key in config.data) {
		form_data.append(key, typeof config.data[key] == 'object' ? JSON.stringify(config.data[key]) : config.data[key]);
	}
	config.data = form_data;

	if (store.state.session) {
		config.url += '?session_id=' + store.state.session;
	}
	return config;
});

Vue.component('UserSearch', () => import('./components/UserSearch.vue'));
Vue.component('Logo', () => import('./components/Logo.vue'));
Vue.component('Menu', () => import('./components/Menu.vue'));
Vue.component('Jour', () => import('./components/Jour.vue'));
Vue.component('PaginationPlanning', () => import('./components/PaginationPlanning.vue'));

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

