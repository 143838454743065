import Vue from 'vue'
import VueRouter from 'vue-router'
import Planning from '../views/Planning.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/stats',
    component: () =>
    import ('../views/Stats.vue')

  },
  {
    path: '/planning/equipe/:equipe_id/:slug/:personne_id',
    name: 'PlanningEquipeUser',
    component: Planning
  },
  {
    path: '/planning/equipe/:equipe_id',
    name: 'PlanningEquipe',
    component: Planning
  },
  {
    path: '/planning/:slug/:personne_id',
    name: 'PlanningUser',
    component: Planning
  },
  {
    path: '/planning/:slug/:personne_id/:annee/:mois',
    name: 'PlanningAnneeMois',
    component: Planning
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
