export const Absences = {
  methods: {
    ajouterAbsence(date, type, callback = false, valeur = 1) {

      let data = {
        user_id: this.user.id,
        date: this.formatDate(date),
        type,
        valeur,
      };
      this.api
        .post("declarer-absence", {
          absence: data,
          precedente: this.$store.state.absencePrecedente,
        }).then((response) => {
          let absences = response.data;
          absences.forEach(absence => {
            this.$store.commit('ajouterAbsence', absence);
          });
          this.$bus.$emit('compter-absences-periode-courante');
          if (callback) {
            callback();
          }
        });
    },

    absencesRange(absences) {
      let prec = false;
      let ranges = [];
      let rangeDef = { start: '', end: '', cssClass: '', title: '', absences: [] }
      let range = JSON.parse(JSON.stringify(rangeDef));
      let prec_id = prec?.user?.id || prec.nom;

      let couleur_main = '#333'
      let couleur_contrast = '#fff';
      absences.forEach(absence => {
        if (prec.user) {
          couleur_main = prec.user.couleurs.main;
          couleur_contrast = prec.user.couleurs.contrast;
        }

        if (prec) {
          if (this.toYYYYMMDD(prec.date) != this.toYYYYMMDD(absence.date) - 1) {
            range.end = prec.date;
            range.cssClass = prec.type + ' valeur-' + (prec.valeur == 1 ? 'full' : 'half') + ' user-' + prec_id;
            range.style = 'background:' + couleur_main + ';color:' + couleur_contrast;
            ranges.push(range);
            range = JSON.parse(JSON.stringify(rangeDef));
            prec = false;
          }
        }
        if (!prec && absence) {
          if (absence.user) {
            range.title = absence.user.nom;
          } else if (absence.nom) {
            range.title = absence.nom;
          }
          range.start = absence.date;
        }
        if (absence) {
          range.absences.push(absence);
        }
        prec = absence;
      })
      if (prec) {
        range.end = prec.date;
        range.cssClass = prec.type + ' valeur-' + (prec.valeur == 1 ? 'full' : 'half') + ' user-' + prec_id;
        range.style = 'background:' + couleur_main + ';color:' + couleur_contrast;
        ranges.push(range);
      }
      console.log({ranges})
      return ranges;
    }
  }
}