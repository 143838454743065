<template>
  <div class="dropdown is-active has-text-left">
    <div class="dropdown-trigger">
      <div class="field">
      <label class="label">Voir le planning de ...</label>
        <p class="control is-expanded has-icons-right">
          <input
            class="input"
            type="search"
            v-model="recherche"
            placeholder="Chercher une personne…"
            @keyup="doSearch"
          />
          <span class="icon is-small is-right">
            <i class="fas fa-search"></i>
          </span>
        </p>
      </div>
    </div>
    <div
      v-if="loading || this.recherche && users.length"
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <button v-if="loading" class="chargement button is-loading"></button>
        <template v-else v-for="(user, index) in users">
          <hr v-if="index" class="dropdown-divider" />
          <router-link :to="{name:'PlanningUser',params:{slug : user.slug, personne_id:user.personne_id}}" class="dropdown-item">
            <p class="title is-5">{{user.nom}}</p>
            <span class="tag">{{user.email}}</span>
          </router-link>
        </template>
          <div v-if="plusDeResultats" class="dropdown-item">
            <hr class="dropdown-divider" />
            <small>Affiner votre recherche<br>pour voir d'autres résultats &hellip;</small>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recherche: "",
      users: [],
      plusDeResultats: false,
      loading: false,
    };
  },
  watch: {
    $route() {
      this.recherche = "";
    },
  },
  methods: {
    doSearch() {
      this.plusDeResultats=false
      clearTimeout(this.loading);
      this.loading = setTimeout(() => {
        this.api
          .get("users", {
            params: { limit: 6, recherche: { nom: this.recherche, email: this.recherche } },
          })
          .then((response) => {
            let users = response.data.users;
            this.plusDeResultats = users.length > 5;
            this.users = users.slice(0, 5);
            this.loading = false;
          });
      }, 500);
    },
  },
};
</script>

<style scoped>
.chargement {
  width: 100%;
  border: none;
}
</style>