<template>
  <div>
    <div class="columns">
      <div class="column">
        <h1 class="title">
          {{ titre }}
        </h1>
      </div>
      <div class="column is-one-third has-text-right" v-if="user && $store.getters.isAdmin">
        <UserSearch />
      </div>
    </div>
    <!--<small v-if="this.user" class="colum is-one-third has-text-left"><i>Le compteur des absences est temporairement
        indisponbile.<br>Contactez compta@sopress.com en cas de questions</i></small>-->

    <div v-if="this.user" class="colum is-one-third has-text-left">
      <p class="subtitle is-6">
        <img src="../assets/vacances.png" />
        Jours de congés pris entre le 1er juin {{ current_year }} et le 31 mai {{ current_year + 1 }}:
        <b>{{ conge_pris }} jours</b>. Il vous reste {{ conge_restants }} jours.<sup>*</sup>
      </p>
    </div>

    <evenement />
    <full-calendar :events="evenements" lang="fr" @eventClick="eventClick" @dayClick="dayClick" :firstDay="1">
      <template v-slot:fc-body-card>
        <div class="is-size-6">
          <div class="control">
            Type d'absence:

            <template v-for="typeAbsence in $store.state.typesAbsences">
              <label :key="typeAbsence.slug">
                <input type="radio" v-model="type" :value="typeAbsence.slug"><span class="radio"
                  :class="typeAbsence.slug">{{ typeAbsence.lib }}</span>
              </label>
            </template>
          </div>
        </div>
        <div class="is-size-6">
          <div class="control">

            <label>
              Afficher les vacances et jours fériés
              <input type="checkbox" v-model="afficherVacances" value="true">
            </label>
          </div>
          <br><small>

            <sup>*</sup> : ce total ne prends pas en compte le solde des années précédentes, qui peut être négatif. <a
              href="https://sogest.sopress.net/index.php?w=documents&m1=1&m2=1&mode=perso&p=1" target="_blank">Consultez
              vos
              bulletins de paye</a> pour avoir le chiffre exact.
          </small>
        </div>
      </template>
    </full-calendar>
  </div>
</template>

<script>
import Evenement from "@/components/Evenement.vue";
export default {
  components: { Evenement },
  data() {
    return {
      user: false,
      type: "conge",
      conge_pris: null,
      conge_restants: null,
      afficherVacances: true,
      joursFeries: [],
      vacances: [],
      alternant: {
        conges_annee_precedente: null,
        conges_annee_actuelle: null,
        conges_pris: null,
        conges_restants: null,
      }

    };
  },
  watch: {
    $route() {
      this.chargementTypesAbsences(() => {
        this.chargementPlanning();
      });
    },
  },
  mounted() {
    this.chargementTypesAbsences(() => {
      this.chargementPlanning();
    });
    this.$bus.$on("charger-absences", this.chargerAbsences);
    this.$bus.$on(
      "compter-absences-periode-courante",
      this.compterAbsencesPeriodeCourante
    );
  },
  computed: {
    current_year() {
      const today = new Date();
      const currentYear = today.getFullYear();
      // Check if today's date is before June 1st
      if (today < new Date(currentYear, 5, 1)) { // Months are 0-indexed, June is 5
        return currentYear - 1;
      } else {
        return currentYear;
      }
    },
    titre() {
      if (this.user) {
        return this.user.nom;
      } else if (this.$store.state.equipe) {
        return 'Équipe "' + this.$store.state.equipe.libelle + '"';
      }
    },
    where() {
      let where = {};
      if (this.user) {
        where.user_id = this.user.id;
      } else if (this.$store.state.equipe) {
        where.user_id = this.$store.state.equipe.users.map((user) => user.id);
      }
      return where;
    },
    evenements() {
      let absences = {};
      this.$store.state.typesAbsences.forEach((typeAbsence) => {
        absences[typeAbsence.slug] = [];
      });
      this.$store.state.absences.forEach((absence) => {
        if (absence.type) {
          absences[absence.type].push(absence);
        }
      });
      const typesAbsences = this.$store.state.typesAbsences.slice(0);

      if (this.afficherVacances) {
        typesAbsences.push({ slug: 'vacances' });
        typesAbsences.push({ slug: 'jours-feries' });
        absences['jours-feries'] = [];
        Object.entries(this.joursFeries).forEach(jourFerie => {
          absences['jours-feries'].push({
            date: jourFerie[0],
            nom: jourFerie[1],
            type: 'jour-ferie',
            valeur: 1
          });
        });

        absences['vacances'] = [];
        this.vacances.forEach(vacance => {
          absences['vacances'].push({
            date: vacance.date,
            nom: this.nomVacance(vacance.summary),
            type: 'vacance',
            valeur: 1
          });
        });
      }

      let out = false;
      typesAbsences.forEach((typeAbsence) => {
        if (!out) {
          out = this.absencesRange(absences[typeAbsence.slug]);
        } else {
          out = out.concat(this.absencesRange(absences[typeAbsence.slug]))
        }
      });


      return out;
      // return this.absencesRange(absences.conge).concat(
      //   this.absencesRange(absences.autre_absence)
      // );
    },
  },
  methods: {
    dayClick(day) {
      // console.log(day)
      if (this.user) {
        // if (this.user && this.conge_restants > 0) {
        this.ajouterAbsence(day, this.type);
      }
    },
    eventClick(e) {
      this.$bus.$emit("afficher-evenement", e);
    },
    chargementTypesAbsences(callback) {
      this.api.get("types-absences").then((response) => {
        this.$store.commit("setTypesAbsences", response.data);
        let style = document.createElement("style");
        style.id = "dynstyle";
        let code = [];
        this.$store.state.typesAbsences.forEach((typeAbsence) => {
          code.push(
            `.${typeAbsence.slug} {  background: ${typeAbsence.color}; color: ${typeAbsence.alt_color};} .event-item.${typeAbsence.slug} {border-bottom: 3px solid ${typeAbsence.color};}`
          );
        });
        code.push(
          `.jour-ferie {  background: #eee !important} .event-item.jour-ferie {border-bottom: 3px solid #ccc;}`
        );
        code.push(
          `.vacance {  background: #eee !important} .event-item.vacance {border-bottom: 3px solid #accfcd;}`
        );
        style.innerHTML = code.join("");
        document.querySelector("#dynstyle")?.remove();
        document.head.appendChild(style);

        callback();
      });
      //
    },
    chargementPlanning() {
      this.user = false;
      this.$store.commit("setEquipe", false);
      this.$store.commit("setAbsences", []);

      fetch('https://calendrier.api.gouv.fr/jours-feries/metropole.json').then(response => response.json()).then(response => {
        this.joursFeries = response;
      })
      fetch('https://tools.sopress.net/wget/?u=https://fr.ftp.opendatasoft.com/openscol/fr-en-calendrier-scolaire/Zone-A-B-C-Corse.ics').then(response => response.text()).then(response => {
        this.vacances = this.extractEvents(response);
      })
      if (this.$route.params.equipe_id) {
        this.api
          .get("equipes", {
            params: { clause: { id: this.$route.params.equipe_id } },
          })
          .then((response) => {
            this.$store.commit("setEquipe", response.data[0]);
            this.chargerAbsences();
          });
      }
      if (this.$route.params.personne_id) {
        this.self =
          this.$route.params.personne_id == this.$store.getters.personneId;
        this.personne_id = this.$route.params.personne_id;
        this.getUser(this.personne_id).then((data) => {
          this.user = data;
          this.chargerAbsences();
        });
      }
    },
    compterAbsencesPeriodeCourante() {
      let date_debut = this.current_year + "-06-01";
      let date_fin = this.current_year + 1 + "-05-31";
      this.api
        .get("table", {
          params: {
            nom: "absences",
            where: {
              "date:>=": date_debut,
              "date:<=": date_fin,
              user_id: this.where.user_id,
              type: "conge",
            },
            trt: true,
          },
        })
        .then((response) => {
          let absences = response.data;
          let somme_conge_pris = 0;
          absences.forEach(absence => {
            console.log({ absence })
            somme_conge_pris += Number(absence.valeur);
          })
          this.conge_pris = somme_conge_pris;
          this.conge_restants = 30 - this.conge_pris;
          this.loading = false;

          // if (this.user['contrat'] == 'alternance') {
          //   let debut_alternance = new Date(this.user['debut_alternance']);
          //   // let fin_alternance = new Date(this.user['fin_alternance']);

          //   let annee_fin_conges_prochain = new Date().getFullYear() + 1;
          //   let date_fin_conges_prochain = new Date("06/01/" + annee_fin_conges_prochain);
          //   let annee_actuelle = new Date().getFullYear();
          //   let mois_actuel = new Date().getMonth();
          //   let jour_actuel = new Date().getDay();
          //   let date_actuelle = jour_actuel+mois_actuel+annee_actuelle;
          //   let date_fin_conges = new Date("06/01/" + annee_actuelle)

          //   console.log(date_actuelle, date_fin_conges)


          //   if (debut_alternance < date_fin_conges) {  //cumuler les mois du début de l'alternance au 1er juin 
          //     this.alternant.conges_annee_actuelle = (date_fin_conges_prochain.getTime() - debut_alternance.getTime()) / 1000;
          //     this.alternant.conges_annee_actuelle /= (60 * 60 * 24 * 7 * 4);
          //     this.alternant.conges_annee_actuelle = Math.floor(this.alternant.conges_annee_actuelle) * 2.5;
          //     this.alternant.conges_pris = somme_conge_pris;

          //     if (date_actuelle == date_fin_conges && (date_fin_conges - debut_alternance < 0)) {   //au 1er juin, réinitialiser le compterur
          //         this.alternant.conges_annee_precedente = this.alternant.conges_annee_actuelle;
          //         this.alternant.conges_annee_actuelle = 0;
          //         this.alternant.conges_pris = somme_conge_pris;
          //         this.alternant.conges_restants = (this.alternant.conges_annee_precedente + this.alternant.conges_annee_actuelle) - this.alternant.conges_pris;
          //       }
          //   }

          //   console.log(debut_alternance.getTime() - date_fin_conges_prochain.getTime());
          //   // console.log(date_fin_conges_prochain.getTime() - debut_alternance.getTime())
          //   if (debut_alternance.getTime() - date_fin_conges_prochain.getTime() < 0)    //une année s'est écoulé depuis son arrivé en entreprise, l'alternant perds ses jours de l'année passé non prit
          //   {
          //     console.log("ici");
          //     this.alternant.conges_annee_precedente = 0;
          //     this.alternant.conges_annee_actuelle = this.alternant.conges_annee_actuelle - this.alternant.conges_annee_precedente;
          //     this.alternant.conges_restants = this.alternant.conges_annee_actuelle - this.alternant.conges_pris;
          //   }

          // } else {
        });
    },
    chargerAbsences() {
      this.loading = true;
      this.api
        .get("table", {
          params: { nom: "absences", where: this.where, trt: true },
        })
        .then((response) => {
          this.$store.commit("setAbsences", response.data);
        })
        .catch(() => {
          // this.logOut();
          document.location.reload();
        })
        .finally(() => {
          this.compterAbsencesPeriodeCourante();
        });
    },
  },
};
</script>

<style lang="scss">
.header-center {
  display: contents;
}

.next-month,
.prev-month {
  flex: 1
}

body .full-calendar-body .dates .dates-events .events-week .events-day {
  min-height: 100px;
}

.radio {
  padding-inline: 0.5em;
  margin-inline: 0.5em;
}

.is-end,
.is-start {
  &.valeur-half {
    width: 50% !important;
  }
}

.is-start:not(.is-end).valeur-half {
  margin-left: 50% !important;
}

img {
  width: 20px;
  margin-right: 5px;
}

.week-row .day-cell:last-of-type,
.events-week .events-day:last-of-type,
.weeks .week:last-of-type {
  display: none;
}
</style>