export const Outils = {
	data() {
		return {
			nomsJours: [
				"dimanche",
				"lundi",
				"mardi",
				"mercredi",
				"jeudi",
				"vendredi",
				"samedi",
			]

		}
	},
	computed: {
	},
	methods: {
		nomVacance(nom) {
			nom = nom.replace("Vacances de la ","");
			nom = nom.replace("Vacances de ","");
			nom = nom.replace("Vacances d'","");
			nom = nom.replace("Zones ","");
			nom = nom.replace("Zone ","");
			return nom;
			// const zone = (nom.split(' - ')[1])

			// return zone;
		},
		extractEvents(icsData) {
			const events = [];
			const eventRegex = /BEGIN:VEVENT([\s\S]*?)END:VEVENT/g;
			let match;
			const currentYear = new Date().getFullYear(); // Récupération de l'année en cours

			while ((match = eventRegex.exec(icsData))) {
				const eventBlock = match[1];
				const dtstartMatch = /DTSTART;VALUE=DATE:(\d{8})/.exec(eventBlock);
				const dtendMatch = /DTEND;VALUE=DATE:(\d{8})/.exec(eventBlock);
				const summaryMatch = /SUMMARY:(.*)/.exec(eventBlock);

				if (dtstartMatch && dtendMatch && summaryMatch) {
					const startDate = new Date(`${dtstartMatch[1].substring(0, 4)}-${dtstartMatch[1].substring(4, 6)}-${dtstartMatch[1].substring(6, 8)}`);
					const endDate = new Date(`${dtendMatch[1].substring(0, 4)}-${dtendMatch[1].substring(4, 6)}-${dtendMatch[1].substring(6, 8)}`);
					endDate.setDate(endDate.getDate() - 1); // Adjust because DTEND is exclusive

					for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
						if (date.getFullYear() === currentYear) { // Vérification que la date est dans l'année en cours
							events.push({
								date: date.toISOString().substring(0, 10),
								summary: summaryMatch[1]
							});
						}
					}
				}
			}

			return events;
		}
		,


		isSaturday(d) {
			const date = new Date(d);
			return date.getDay() === 6;
		},
		logOut() {
			this.$store.commit('resetUser');
			Sso.logOut({ title: 'Absences' });
		},
		checkSession() {
			this.api.get("check-session").then((response) => {
				if (!response.data["check-session"]) {
					this.$store.commit("resetUser");
				}
			});
		},
		nomMois(annee, mois) {
			return new Date(annee, mois).toLocaleDateString("fr", {
				month: "long",
			});
		},

		debutMois() {
			let options = { year: "numeric", month: "numeric" };
			return new Date().toLocaleString("fr-CA", options).split(",")[0] + "-01";
		},
		finMois() {
			let d = new Date();
			d.setMonth(d.getMonth() + 1);
			d.setDate(0);
			return d.toLocaleString("fr-CA").split(",")[0];
		},
		async getUser(personne_id) {
			window.users = window.users || {}
			if (!window.users[personne_id]) {
				let response = await this.api.get('users', { params: { clause: { personne_id: personne_id } } })
				window.users[personne_id] = response.data.users.pop()
			}
			return window.users[personne_id]
		},
		async getUser(personne_id) {
			window.users = window.users || {}
			if (!window.users[personne_id]) {
				let response = await this.api.get('users', { params: { clause: { personne_id: personne_id } } })
				window.users[personne_id] = response.data.users.pop()
			}
			return window.users[personne_id]
		},
		toYYYYMMDD(date) {
			let ret = date.replaceAll('-', '');
			return ret;
		},
		formatDate(date) {
			const offset = date.getTimezoneOffset()
			date = new Date(date.getTime() - (offset * 60 * 1000))
			return date.toISOString().split('T')[0]
		},

	}
}

