<template>
  <section class="section">
    <template v-if="$store.getters.isConnected && this.$store.state.session">
      <div class="columns">
        <Menu />
        <div class="column">
          <router-view />
        </div>
      </div>
    </template>
    <!-- <Login titre="Absences" v-else /> -->
  </section>
</template>
<script>
// import Login from "./views/Login.vue";
export default {
  name: "App",
  // components: { Login },
  mounted() {
    let test = 0;
    this.checkSession();
    console.log('debug', 1);
    if (!this.$store.getters.isConnected) {
      console.log('debug', 2);
      Sso.logIn(this.$route.query.jwt, { title: "Absences" }, (data) => {
        console.log('debug', 3);
        this.$store.commit("setSession", data);
        console.log(this.$store.getters.isSetSession);
        let user = data.user;
        this.$router.push({
          name: "PlanningUser",
          params: { slug: user.slug, personne_id: user.personne_id },
        });
      });
      console.log('debug', 4);
    } else {
      console.log('debug', 5);
      if (!this.$route.params.personne_id && !this.$route.params.equipe_id) {
        console.log('debug', 6);

        this.$router.push({
          name: "PlanningUser",
          params: {
            slug: this.$store.state.user.slug,
            personne_id: this.$store.state.user.personne_id,
          },
        });
      }
      console.log('debug', 7);
    }
  },
};
</script>
<style lang="scss">
.tag:empty {
  display: none;
}
</style>
