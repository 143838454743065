<template>
  <div
    class="jour"
    :class="{ absence }"
    :data-type="absence ? absence.type : false"
    :data-valeur="absence ? absence.valeur : false"
  >
    <div>
      <template v-if="jour.date">
        <div>
          <span class="numero">{{ jour.jour }}</span>
          <!-- <template v-if="absence">
            <div class="tag">{{ absence.type }}</div>
          </template>-->
          <div class="buttons has-addons is-centered">
            <template v-if="absence">
              <button
                v-if="absence.type == 'conge'"
                class="button is-rounded is-small"
                @click="demiJournee"
                :aria-label="absence.valeur==1 ? 'Demi-journée' : 'Journée entière'"
                data-balloon-pos="up"
              >
                <span class="icon is-small">
                  <i class="fas fa-page-break"></i>
                </span>
              </button>
              <button
                class="button is-rounded is-small"
                @click="effacerAbsence"
                aria-label="Retirer"
                data-balloon-pos="up"
              >
                <span class="icon is-small">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </button>
            </template>
            <template v-else>
              <template v-if="dimanche"></template>
              <template v-else>
                <button
                  data-type="conge"
                  class="button is-small"
                  @click="declarerAbsence('conge')"
                >Congés</button>
                <button
                  data-type="maladie"
                  class="button is-small"
                  @click="declarerAbsence('maladie')"
                >Maladie</button>
              </template>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["jour", "user", "mois"],
  data() {
    return {
      loading: false,
      absenceTemporaire: null,
    };
  },
  mounted() {
    if (this.jour) {
      // this.$bus.$on("ajouter-conge-jour-" + this.jour.date, () => {
      //   this.ajouterAbsence("conge");
      // });
      this.$bus.$on("ajouter-conge-jour", (date) => {
        if (date == this.jour.date) {
          this.ajouterAbsence("conge");
        }
      });
    }
  },
  computed: {
    dimanche() {
      return this.jour.nom == "dimanche";
    },
    absence() {
      if (this.jour) {
        if (this.absenceTemporaire !== null) {
          return this.absenceTemporaire;
        }
        return this.$store.state.absences.find((absence) => {
          if (this.jour.date == absence.date) {
            return absence;
          }
        });
      }
    },
  },
  methods: {
    loadingEnd() {
      setTimeout(() => {
        this.loading = false;
      }, 0);
    },
    demiJournee() {
      this.api
        .post("demi-journee", {
          id: this.absence.id,
        })
        .then((response) => {
          this.absenceTemporaire = response.data
     //     this.$bus.$emit("charger-absences");
        });
    },
    effacerAbsence(callback = false) {
      let where = {
        id: this.absence.id,
      };

      this.api
        .delete("table", { params: { nom: "absences", where } })
        .then((response) => {
          this.absenceTemporaire = null;
          this.$bus.$emit("charger-absences");
          this.loadingEnd();
          if (typeof callback == "function") callback();
        });
    },
    ajouterAbsence(type, callback = false, valeur = 1) {
      if (this.dimanche || (this.absence && valeur == 1)) {
        return callback ? callback() : false;
      }
      this.absenceTemporaire = type;

      let data = {
        user_id: this.user.id,
        date: this.jour.date,
        type,
        valeur,
      };
      this.api
        .post("declarer-absence", {
          absence: data,
          precedente: this.$store.state.absencePrecedente,
        })
        .then((response) => {
          let absences = response.data;
          this.$store.commit("absencePrecedente", absences[0]);
          console.log(absences[0]);
          absences.forEach((absence) => {
            this.$bus.$emit("ajouter-absence", absence);
          });

          this.absenceTemporaire = null;
          if (callback) {
            callback();
          }
          this.loadingEnd();
        });
    },

    declarerAbsence(type) {
      this.loading = true;
      this.ajouterAbsence(type);
    },
    poseAutoDuSamedi(type) {
      if (type == "conge" && this.jour.nom == "vendredi") {
        this.$bus.$emit("vendredi-pose");

        let demain = new Date(this.jour.date);
        demain.setDate(demain.getDate() + 1);
        // this.$bus.$emit("ajouter-conge-jour-" + this.formatDate(demain));
        this.$bus.$emit("ajouter-conge-jour", this.formatDate(demain));
      }
    },
  },
};
</script>
<style lang="scss">
.jour {
  &[data-valeur="0.5"] {
    background: repeating-linear-gradient(
      45deg,
      #fee100,
      #fee100 50%,
      #ffffff 50%,
      #ffffff 100%
    ) !important;
  }
  .buttons {
    visibility: hidden;
    position: absolute;
    bottom: 0.5em;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) scale(0.8);
  }
  &:hover {
    .buttons {
      visibility: visible;
    }
    & > div {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  height: 0;
  //overflow: hidden;
  padding-bottom: 80%;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;
  & > div {
    transition: background 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .numero {
      // z-index: 1;
      position: absolute;
      font-size: 0.7em;
      top: 0.5em;
      right: 0.5em;
      width: 2em;
      height: 2em;
      text-align: center;
      line-height: calc(2em - 4px);
      border-radius: 50%;
      color: black;
      background: white;
      border: 1px solid #ccc;
    }
    .tag {
      margin-top: 0.5em;
      margin-left: 0.5em;
    }
  }
  [aria-label][data-balloon-pos][data-balloon-pos]:hover::after {
    z-index: 1000;
  }
}
</style>